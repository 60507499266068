import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createArticle, getArticles, setWelcomeTemplate } from "../../services/articles.service";
import { AlertContext, convertFirebaseTimestampToString, copyToClipboard, CustomPopover, Loading, Separator, TextWithTooltip, VideoHelper } from "@moreirapontocom/npmhelpers";
import { SkeletonSubscribers } from "../../components/Skeletons/Skeletons.component";

const ArticlesView = () => {
  const navigate: any = useNavigate();
  const {setAlert} = useContext(AlertContext);

  const pageEnvKey: string = "ARTICLES";
  const env_title: string = process.env[`REACT_APP_VIDEO_TUTORIAL_${pageEnvKey}_TITLE`] || "";
  const env_description: string = process.env[`REACT_APP_VIDEO_TUTORIAL_${pageEnvKey}_DESCRIPTION`] || "";
  const env_video_url: string = process.env[`REACT_APP_VIDEO_TUTORIAL_${pageEnvKey}_VIDEO_URL`] || "";

  useEffect(() => {
    _getArticles();
  }, []);

  const [loadingArticles, setLoadingArticles] = useState(true);
  const [articles, setArticles] = useState([]);
  const [welcomeArticleId, setWelcomeArticleId] = useState("");

  const _getArticles = async () => {
    setLoadingArticles(true);
    const response: any = await getArticles().then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setArticles(response.articles);
      setWelcomeArticleId(response.welcomeArticleId);
      setLoadingArticles(false);
      return;
    }

    setLoadingArticles(false);
    console.log(response);
  };

  const [loadingCreateArticle, setLoadingCreateArticle] = useState(false);

  const _createArticle = async () => {
    setLoadingCreateArticle(true);
    const response: any = await createArticle().then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setLoadingCreateArticle(false);
      navigate(`/panel/articles/${response.newArticleId}`);
      return;
    }

    setLoadingCreateArticle(false);
    console.log("Error creating article", response);
  };

  /*
  const [loadingSetWelcomeTemplate, setLoadingSetWelcomeTemplate] = useState(false);
  const _setWelcomeTemplate = async (articleId: string = "none") => {
    setLoadingSetWelcomeTemplate(true);
    const response: any = await setWelcomeTemplate(articleId).then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setLoadingSetWelcomeTemplate(false);
      setWelcomeArticleId(articleId);
      setAlert({type: "success", message: "Template de boas-vindas atualizado"});
      return;
    }

    const message: string = response.message === "AI_CONTENT_VALIDATION" ? "Conteúdo potencialmente perigoso" : "Erro ao definir o template de boas-vindas";

    setLoadingSetWelcomeTemplate(false);
    setAlert({type: "danger", message});
    console.log("Error setting welcome template", response);
  };
  */

  return <>
    <VideoHelper
      hanlerTitle="Tutorial em Vídeo"
      title={env_title}
      description={env_description}
      videoUrl={env_video_url}
      colorScheme={{
        backgroundColor: "#e67e22",
        textColor: "white",
      }}
    />

    <div className="Articles">

      <div className="row align-items-center">
        {!loadingArticles && articles.length > 0 && <>
          <div className="col">
            <strong>Artigos</strong>
          </div>
        </>}
        <div className="col text-end">
          <button
            type="button"
            onClick={() => _createArticle()}
            disabled={loadingCreateArticle}
            className="btn btn-primary">
              <Loading loading={loadingCreateArticle} parent="inline" color="text-white" />
              {!loadingCreateArticle && <i className="fas fa-plus me-2"></i>} Novo Artigo
          </button>
        </div>
      </div>

      <Separator size={21} />

      {loadingArticles && <SkeletonSubscribers />}

      {!loadingArticles && articles.length === 0 && <>
        <div className="text-center">
          <strong>Você ainda não criou nenhum artigo.</strong>
          <Separator size={14} />
          <small>
            Crie seu primeiro artigo para enviar para sua lista de emails.
          </small>

          <Separator size={21} />

          <img src="https://criarti.com/folder.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />
        </div>
      </>}

      {!loadingArticles && articles.length > 0 && <>
        {/*
        <div className="row">
          <div className="col-12 col-md-6">

            <TextWithTooltip
              id="tooltip-article-welcome-template"
              tooltip="O email de boas-vindas será enviado automaticamente para os novos assinantes da sua newsletter">
                {["", "none"].includes(welcomeArticleId) && <>
                  <i className="fas fa-exclamation-triangle text-warning me-2"></i>
                </>}
                {!["", "none"].includes(welcomeArticleId) && <>
                  <i className="fas fa-check-circle text-success me-2"></i>
                </>}
                <strong>Template de boas-vindas</strong> <i className="fas fa-question-circle text-muted opacity-50 ms-2 cursor-pointer" />
            </TextWithTooltip>

            <Separator size={7} />

            <div className="form-group">
              <label>
                <small className="text-muted me-2">
                  Selecione um artigo para ser o email de boas-vindas à sua newsletter
                </small> <Loading loading={loadingSetWelcomeTemplate} parent="inline" />
              </label>

              <Separator size={7} />

              <select
                className="form-select"
                onChange={(e) => _setWelcomeTemplate(e.target.value)}
                value={welcomeArticleId}
                disabled={loadingArticles || loadingCreateArticle || loadingSetWelcomeTemplate || articles.length === 0}>
                  <option value="none">Selecione um artigo</option>
                  {articles.filter((item: any) => item.status === "draft").map((article: any) => {
                    return <option
                      value={article.id}
                      key={`article-as-template-${article.id}`}>
                        {article.title}
                    </option>
                  })}
              </select>
            </div>

            {["", "none"].includes(welcomeArticleId) && <>
              <Separator size={14} />
              <div className="alert alert-secondary">
                <i className="fas fa-info-circle me-2"></i>
                O email de boas-vindas é opcional mas <strong>altamente recomendado</strong>.
                <Separator size={7} />
                <small>
                  Além de iniciar um diálogo com seu público, você pode apresentar o conteúdo que será enviado e pedir que compartilhe com amigos e familiares.
                </small>
              </div>
            </>}

          </div>
        </div>

        <Separator size={28} />
        */}

        <div className="text-center">
          <small className="text-muted">
            {articles.length} artigos encontrados
          </small>
        </div>

        <Separator size={7} />

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Última atualização</th>
              <th scope="col">Título</th>
              {/*
              <th scope="col">
                <TextWithTooltip
                  id="tooltip-article-link"
                  tooltip="Se quiser enviar o link de um artigo em outro artigo, utilize este link. 
                      O sistema vai personalizar o link de cada assinante automaticamente. 
                      Importante: Este não é um link para ser compartilhado em redes sociais!">
                    Link <i className="fas fa-question-circle text-muted opacity-50 ms-2 cursor-pointer" />
                </TextWithTooltip>
              </th>
              */}
              <th scope="col">Status</th>
              <th scope="col" className="text-end"></th>
            </tr>
          </thead>
          <tbody>
            {articles.map((article: any) => {
              return <React.Fragment key={`article-${article.id}`}>
                <tr>
                  <td className="text-muted">
                    <small>
                      {convertFirebaseTimestampToString(article.updated_at, "DD/MM/YYYY HH:mm:ss")}
                    </small>
                  </td>
                  <td>{article.title}</td>
                  {/*
                  <td>
                    {article.status === "draft" && <>-</>}
                    {article.status === "sent" && <>
                      <button
                          type="button"
                          onClick={() => {
                            copyToClipboard(`[item slug="moreirapontocom/${article.slug}" title="ESCREVA ALGUMA COISA AQUI"]`);
                            setAlert({type: "success", message: "Link copiado"});
                          }}
                          className="btn btn-link btn-sm underline-0">
                            <i className="fas fa-link me-2"></i> Copiar link
                        </button>
                    </>}
                  </td>
                  */}
                  <td>
                    {article.id === welcomeArticleId && <span className="badge bg-success" style={{ minWidth: "100px" }}>Boas-vindas</span>}

                    {article.id !== welcomeArticleId && <>
                      {article.status === "draft" && <span className="badge bg-secondary opacity-50" style={{ minWidth: "100px" }}>Rascunho</span>}
                      {article.status === "sent" && <span className="badge bg-primary" style={{ minWidth: "100px" }}>Enviado</span>}
                    </>}
                  </td>
                  <td className="text-end">
                    {article.status === "draft" && <>
                      <Link to={`/panel/articles/${article.id}`} className="btn btn-link inline">
                        editar
                      </Link>
                    </>}

                    {article.status === "sent" && <>
                      <Link to={`/panel/articles/${article.id}/results`} className="btn btn-link inline">
                        ver detalhes
                      </Link>
                    </>}
                  </td>
                </tr>
              </React.Fragment>
            })}
          </tbody>
        </table>
      </>}
    </div>
  </>
};

export default ArticlesView;