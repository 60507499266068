import { Separator } from "@moreirapontocom/npmhelpers";
import { Link } from "react-router-dom";

const NewAccountSuccess = () => {
  return <div className="NewAccountSuccess container bg-white p-4 shadow-sm rounded-3 mt-5">
    <div className="text-center">

      <img src="https://criarti.com/c@2x.png" alt="Criarti" className="img-fluid" style={{ width: "60px" }} />

      <Separator size={40} />

      <p>
        <strong>Sua conta foi criada com sucesso.</strong><br />
        Antes de começar, nossa equipe vai entrar em contato com você em breve <br />
        para te ajudar a dar os primeiros passos.
      </p>

      <Separator size={20} />

      <Link to="/login" className="btn btn-primary">Ir para o Login &rarr;</Link>

      <Separator size={60} />
      <img src="/welcome-to-comunica121.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />

      <Separator size={20} />
    </div>

    <Separator size={30} />
  </div>;
};

export default NewAccountSuccess;