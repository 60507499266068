import React, { useContext, useEffect, useState } from "react";
import { AlertContext, copyToClipboard, Loading, Separator, Upload, VideoHelper } from "@moreirapontocom/npmhelpers";
import { getStorage, ref } from '@firebase/storage';
import { app } from "../../app-config";
import { getAssets, saveAsset } from "../../services/assets.service";

const AssetsView = () => {
  const { setAlert } = useContext(AlertContext);
  const storage: any = getStorage(app);

  const pageEnvKey: string = "ASSETS";
  const env_title: string = process.env[`REACT_APP_VIDEO_TUTORIAL_${pageEnvKey}_TITLE`] || "";
  const env_description: string = process.env[`REACT_APP_VIDEO_TUTORIAL_${pageEnvKey}_DESCRIPTION`] || "";
  const env_video_url: string = process.env[`REACT_APP_VIDEO_TUTORIAL_${pageEnvKey}_VIDEO_URL`] || "";

  useEffect(() => {
    _getAssets();
  }, []);

  const [loadingAssets, setLoadingAssets] = useState(true);
  const [assets, setAssets] = useState([] as any);
  const [authorHandler, setAuthorHandler] = useState(null as any);
  const [itemPreview, setItemPreview] = useState(null as any);

  const _getAssets = async () => {
    const response: any = await getAssets().then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setAssets(response.assets);
      setAuthorHandler(response.authorHandler);
      setLoadingAssets(false);
      return;
    }

    setAlert({type: "danger", message: "Erro ao carregar as mídias"});
    setLoadingAssets(false);
    console.log(response);
  };

  const [loadingSaveAsset, setLoadingSaveAsset] = useState(false);

  const _saveAsset = async (asset: any) => {
    setLoadingSaveAsset(true);
    const response: any = await saveAsset(asset).then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setAlert({type: "success", message: "Arquivo salvo."});
      setAssets([...assets, response.asset]);
      setLoadingSaveAsset(false);
      return;
    }

    setLoadingSaveAsset(false);
    setAlert({type: "danger", message: "Erro ao salvar o arquivo"});
  };

  return <>
    <VideoHelper
      hanlerTitle="Tutorial em Vídeo"
      title={env_title}
      description={env_description}
      videoUrl={env_video_url}
      colorScheme={{
        backgroundColor: "#e67e22",
        textColor: "white",
      }}
    />

    <div className="Assets">

      <div className="row">
        <div className="col">

          <strong>Arquivos de Mídia</strong>

          <Separator size={7} />

          <small className="text-muted">
            Envie arquivos de mídia para serem utilizados nos seus emails.<br />
            Você pode enviar imagens e documentos em PDF.
          </small>

          <Separator size={21} />

          <Loading loading={loadingAssets} />

          {!loadingAssets && <>
            <Upload
              storage={ref(storage)}
              accept="image/png,image/jpeg,image/jpg"
              folder={`${authorHandler}/assets`}
              showProgress={true}
              loading={false}
              onUpload={async (e: any) => {
                setAlert({type: "success", message: 'Arquivo enviado.'});
                setItemPreview(e);
                await _saveAsset(e);
                console.log(e);
              }}
            />

            <Separator size={21} />

            {assets.length > 0 && <>
              <div className="list-group">
                {assets.map((item: any, index: number) => {
                  return <React.Fragment key={`asset-${index}`}>
                    <button
                      type="button"
                      onClick={() => setItemPreview(item.asset)}
                      className={`list-group-item list-group-item-action ${itemPreview && itemPreview.generatedName === item.asset.generatedName ? "active" : ""}`}>
                        {item.asset.fileName}<br />
                        <small className="opacity-50">
                          {item.asset.size} bytes | {item.asset.contentType}
                        </small>
                      </button>
                  </React.Fragment>
                })}
              </div>
            </>}
          </>}

        </div>
        <div className="col">

          {!loadingAssets && <>
            <small className="text-muted">
              Pré-visualização
            </small>

            <Separator size={7} />

            <Loading loading={loadingSaveAsset} />

            {!loadingSaveAsset && !itemPreview && <>
              <small className="text-muted">
                Selecione um item ao lado para pré-visualizar.
              </small>
            </>}

            {!loadingSaveAsset && itemPreview && <>
              <small className="text-muted">
                Link do arquivo (clique para copiar)<br />
                <input
                  type="url"
                  className="form-control"
                  value={`${itemPreview.folder}/${itemPreview.fileName}`}
                  onChange={() => {}}
                  readOnly
                  onClick={(e: any) => {
                    e.target.select();
                    copyToClipboard(itemPreview.downloadURL);
                    setAlert({type: "success", message: "Link copiado."});
                  }}
                />
              </small>

              <Separator size={14} />

              <img
                src={itemPreview.downloadURL}
                alt={itemPreview.fileName}
                className="img-fluid" />

              <Separator size={21} />

              <small className="text-muted">
                <strong>Link permanente:</strong> {itemPreview.downloadURL}
              </small>
            </>}
          </>}

        </div>
      </div>

    </div>
  </>
};

export default AssetsView;