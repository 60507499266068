import { Separator } from "@moreirapontocom/npmhelpers";

const ErrorHandlerProvider = ({ children }: any) => {
  return <>
    <div className="container bg-white p-4 shadow-sm rounded-3 mt-3">
      <div className="bg"></div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4 text-center">

          <div className="text-center">
            <img src="https://criarti.com/c@2x.png" alt="Criarti" className="img-fluid" style={{ width: "60px" }} />
          </div>

          <Separator size={40} />

          <strong>Página não encontrada</strong>

          <Separator size={10} />

          <small className="text-muted">Por favor, tente novamente mais tarde</small>

          <Separator size={30} />

          <a href="/" className="btn btn-primary">Ir para a página principal &rarr;</a>

        </div>
      </div>
    </div>
  </>
};

export default ErrorHandlerProvider;