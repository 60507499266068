import React, { useContext, useEffect, useState } from "react";
import {
  AlertContext,
  ConfirmContext,
  convertFirebaseTimestampToString,
  Loading,
  Separator,
  TextWithTooltip,
  Upload,
  CsvReader,
  VideoHelper,
} from "@moreirapontocom/npmhelpers";
import { getSubscribers, importSubscribers, unsubscribe } from "../../services/subscription.service";
import { SkeletonSubscribers } from "../../components/Skeletons/Skeletons.component";
import { _calculateSubscribers } from "../../services/helpers.service";
import { getProfile } from "../../services/profile.service";

const SubscribersView = () => {
  const { setAlert } = useContext(AlertContext);
  const { setConfirm } = useContext(ConfirmContext);

  const pageEnvKey: string = "SUBSCRIBERS";
  const env_title: string = process.env[`REACT_APP_VIDEO_TUTORIAL_${pageEnvKey}_TITLE`] || "";
  const env_description: string = process.env[`REACT_APP_VIDEO_TUTORIAL_${pageEnvKey}_DESCRIPTION`] || "";
  const env_video_url: string = process.env[`REACT_APP_VIDEO_TUTORIAL_${pageEnvKey}_VIDEO_URL`] || "";

  useEffect(() => {
    _getSubscribers();
    _getProfile();
  }, []);

  const [loadingSubscribers, setLoadingSubscribers] = useState(true);
  const [subscribers, setSubscribers] = useState([]);

  const _getSubscribers = async () => {
    const response: any = await getSubscribers().then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setSubscribers(response.subscribers);
      setLoadingSubscribers(false);
      return;
    }

    setLoadingSubscribers(false);
    console.log(response);
  };

  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profile, setProfile] = useState(null as any);

  const _getProfile = async () => {
    const response: any = await getProfile().then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setProfile(response.profile);
      setLoadingProfile(false);
      return;
    }

    setAlert({type: "danger", message: "Erro ao carregar perfil"});
    setLoadingProfile(false);
    console.log(response);
  };

  const [loadingUnsubscriber, setLoadingUnsubscriber] = useState(false);
  const [loadingUnsubscriberId, setLoadingUnsubscriberId] = useState("");
  const _unsubscribe = async (subscriber: any) => {
    setLoadingUnsubscriber(true);
    setLoadingUnsubscriberId(subscriber.id);
    const response: any = await unsubscribe(subscriber.authorHandler, subscriber.id, "manual").then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setAlert({type: "success", message: "Assinante removido com sucesso"});

      // Set subscriber status to opt-out
      const _subscribers: any = [...subscribers];
      const index: number = _subscribers.findIndex((s: any) => s.id === subscriber.id);
      _subscribers[index].status = "opt-out";
      _subscribers[index].email = `**********@${_subscribers[index].email.split("@")[1]}`;
      _subscribers.sort((a: any, b: any) => a.status > b.status ? 1 : -1);
      setSubscribers(_subscribers);

      setLoadingUnsubscriber(false);
      setLoadingUnsubscriberId("");
      return;
    }

    setLoadingUnsubscriber(false);
    setLoadingUnsubscriberId("");
  };

  const [csvRawData, setCsvRawData] = useState(null as any);
  const [loadingImportCsv, setLoadingImportCsv] = useState(false);
  const _importContacts = async () => {
    setLoadingImportCsv(true);
    const response: any = await importSubscribers(csvRawData).then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setAlert({type: "success", message: "Contatos importados com sucesso"});
      _getSubscribers();
      setCsvRawData(null);
      setLoadingImportCsv(false);
      return;
    }

    setLoadingImportCsv(false);
    setAlert({type: "danger", message: "Erro ao importar contatos"});
    console.log(response);
  };

  return <>
    <VideoHelper
      hanlerTitle="Tutorial em Vídeo"
      title={env_title}
      description={env_description}
      videoUrl={env_video_url}
      colorScheme={{
        backgroundColor: "#e67e22",
        textColor: "white",
      }}
    />

    <div className="Subscribers">

      {/*
      <div className="alert alert-primary primary-light">
        <small className="text-muted">
          <i className="fas fa-info-circle me-2"></i> É através deste link que os visitantes se inscrevem em sua lista de emails.<br />
          Compartilhe em suas redes sociais, site, blog, etc.
        </small>

        <Separator size={14} />

        <div className="form-group">
          <label>
            <strong>Este é o seu link de Inscrição</strong>
          </label>

          <Separator size={7} />

          <Loading loading={loadingProfile} />

          {!loadingProfile && profile && <>
            <input
              type="text"
              className="form-control"
              onFocus={(e) => {
                e.target.select();
                copyToClipboard(e.target.value);
                setAlert({type: "success", message: "Link copiado para a área de transferência"});
              }}
              value={`${frontendURLWebsite}/u/${profile.handler}`}
              readOnly />
          </>}
        </div>
      </div>

      <Separator size={14} />
      */}

      <strong>Assinantes</strong>

      <Separator size={21} />

      <ul className="nav nav-tabs" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active"
          id="subscribers-tab"
          data-bs-toggle="tab"
          data-bs-target="#subscribers-tab-pane"
          type="button"
          role="tab"
          aria-controls="subscribers-tab-pane"
          aria-selected="true">
            <i className="fas fa-users me-2"></i> Assinantes
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className="nav-link"
          id="import-tab"
          data-bs-toggle="tab"
          data-bs-target="#import-tab-pane"
          type="button"
          role="tab"
          aria-controls="import-tab-pane"
          aria-selected="false">
            <i className="fas fa-upload me-2"></i> Importar Contatos
        </button>
      </li>
    </ul>

    <Separator size={14} />

    <div className="tab-content" id="myTabContent">
      <div className="tab-pane show active" id="subscribers-tab-pane" role="tabpanel" aria-labelledby="subscribers-tab">

        {(loadingSubscribers || loadingProfile) && <SkeletonSubscribers />}

        {(!loadingSubscribers && !loadingProfile) && subscribers.length === 0 && <>
          <strong>Nenhum assinante encontrado</strong>
        </>}

        {(!loadingSubscribers && !loadingProfile) && subscribers.length > 0 && <>
          <div className="text-center">
            <small className="text-muted">
              {subscribers.length} assinantes encontrados &bull; {_calculateSubscribers(subscribers).active} ativos ({_calculateSubscribers(subscribers).percentage}%)
            </small>
          </div>

          <Separator size={7} />

          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nome</th>
                <th scope="col"></th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col" className="text-end">Data Inscrição</th>
              </tr>
            </thead>
            <tbody>
              {subscribers.map((subscriber: any, index: number) => {
                return <React.Fragment key={`subscriber-${index}`}>
                  <tr className={`sub-${subscriber.id.substr(0, 10)} ${subscriber.status === "opt-out" ? "text-muted opacity-5" : ""}`}>
                    <td className="text-muted small">{index + 1}</td>
                    <td>{subscriber.name}</td>
                    <td>

                      {profile.email === subscriber.email && <>
                        <TextWithTooltip
                          id="tooltip-article-result-link"
                          tooltip="Este assinante não pode ser removido">
                            <small className="text-muted">
                              <i className="fas fa-info-circle"></i>
                            </small>
                        </TextWithTooltip>
                      </>}

                      {profile.email !== subscriber.email && subscriber.status === "active" && <>
                        <button
                          type="button"
                          className="btn btn-link btn-sm inline"
                          disabled={loadingUnsubscriber && loadingUnsubscriberId === subscriber.id}
                          onClick={() => {
                            setConfirm({
                              type: "danger", // primary || success || danger || info || warning
                              title: "Tem certeza?",
                              message: `Você está prestes a remover <strong>"${subscriber.name}"</strong> (${subscriber.email}) da sua lista de emails. Esta ação não pode ser desfeita. O assinante não será notificado.`,
                              buttonLabel: "Remover",
                              buttonCancelLabel: "Cancelar",
                              onConfirm: async () => {
                                _unsubscribe(subscriber);
                              },
                            });
                          }}>
                            <Loading loading={loadingUnsubscriber && loadingUnsubscriberId === subscriber.id} parent="inline" />
                            {(!loadingUnsubscriber || (loadingUnsubscriber && loadingUnsubscriberId !== subscriber.id)) && <i className="fas fa-unlink me-2"></i>}
                        </button>
                      </>}
                    </td>
                    <td>{subscriber.email}</td>
                    <td>
                      {subscriber.status === "active" && <span className="badge bg-primary" style={{ minWidth: "100px" }}>Inscrito</span>}
                      {subscriber.status === "opt-out" && <span className="badge bg-secondary opacity-50" style={{ minWidth: "100px" }}>Saiu da Lista</span>}
                    </td>
                    <td className="text-end text-muted">
                      <small>
                        {convertFirebaseTimestampToString(subscriber.subscribed_at, "DD/MM/YYYY")}
                      </small>
                    </td>
                  </tr>
                </React.Fragment>
              })}
            </tbody>
          </table>
        </>}

      </div>
      <div className="tab-pane" id="import-tab-pane" role="tabpanel" aria-labelledby="import-tab">

        <div className="card bg-light border-0">
          <div className="card-body">

            <strong>Selecione um arquivo para importar a lista de contatos</strong>

            <Separator size={14} />

            <div className="row">
              <div className="col-12 col-md-6">

                <div className="form-group">
                  <Upload
                    accept="text/csv"
                    showProgress={true}
                    loading={false}
                    onUpload={(e: any) => setCsvRawData(e.content)}
                  />
                </div>

              </div>
              <div className="col">

                <small className="text-muted">
                  O arquivo deve ser no formato CSV e conter as colunas "Nome" e "Email".<br />
                  Um contato por linha. Baixe um <a href="/demo-import.csv" download>modelo de arquivo</a> para importar.

                  <Separator size={7} />

                  <i className="fas fa-info-circle me-2"></i>
                  Se sua lista tiver mais de 20 contatos, você deverá configurar a autenticação do seu domínio próprio para enviar emails.
                </small>

              </div>
            </div>

          </div>
        </div>

        <Separator size={21} />

        <CsvReader rawData={csvRawData} couterLabel="registros encontrados" />

        <Separator size={21} />

        {csvRawData && <>
          <button
            type="button"
            disabled={loadingImportCsv}
            onClick={() => _importContacts()}
            className="btn btn-primary">
              <Loading loading={loadingImportCsv} parent="inline" color="text-white" />
              {!loadingImportCsv && <i className="fas fa-upload me-2"></i>} Importar Contatos
          </button>
        </>}

      </div>
    </div>

    </div>
  </>
};

export default SubscribersView;