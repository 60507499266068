import { Separator } from "@moreirapontocom/npmhelpers";

const Footer = () => {
  const appVersion: string =  process.env.REACT_APP_VERSION || "0.0.0";

  return <>
    <Separator size={40} />

    <footer>
      <div className="container Footer text-center">
        <small className="text-muted opacity-50">
          &copy; 2025. criarti.com
          <span className="text-light">
            <br />v{appVersion}
          </span>
        </small>
      </div>
    </footer>

    <Separator size={40} />
  </>
};

export default Footer;