import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { useFormik } from "formik";
import { signJWT } from "../../services/jwt.service";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Alert, AlertContext, Loading, Separator } from "@moreirapontocom/npmhelpers";
import { createProfile } from "../../services/profile.service";
import Footer from "../../components/Footer/Footer.component";
import PasswordStrengthBar from 'react-password-strength-bar';
import * as yup from 'yup';

interface User {
  uid: string
  displayName: string
  email: string
}

const Signup = () => {
  const navigate: any = useNavigate();
  const {alert, setAlert} = useContext(AlertContext);
  const [passwordScore, setPasswordScore] = useState(0);

  const initialValues: any = {
    displayName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const [loadingSignup, setLoadingSignup] = useState(false);

  const formikSignup: any = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      displayName: yup.string().required().min(3),
      email: yup.string().email().required(),
      password: yup.string().min(6).required(),
      confirmPassword: yup.string().min(6).required().oneOf([yup.ref("password")], "As senhas não conferem.")
    }),
    onSubmit: (values: any) => {
      const auth: any = getAuth();
      setLoadingSignup(true);
      createUserWithEmailAndPassword(auth, values.email, values.password).then(async (result: any) => {
        const {uid, email}: User = result.user;
        const signed: any = await signJWT({uid, displayName: values.displayName, email} as User);
        localStorage.setItem("access_token", signed);
        const responseCreateProfile: any = await createProfile().then((r: any) => r).catch((e: any) => e);
        if (responseCreateProfile.message === "OK") {
          setLoadingSignup(false);
          console.log("Conta criada com sucesso!");
          navigate("/welcome");
          return;
        }

        console.log("responseCreateProfile", responseCreateProfile);
      }).catch((error: any) => {
        setLoadingSignup(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Error Google SignIn:', errorCode, errorMessage);
      });
    },
  });

  /*
  const authenticate_GoogleSignin = (): void => {
    const auth: any = getAuth();
    setLoadingAuthentication(true);
    signInWithPopup(auth, provider).then(async (result: any) => {
      const {uid, displayName, email, photoURL}: User = result.user;
      const signed: any = await signJWT({uid, displayName, email, photoURL} as User);

      localStorage.setItem("user", JSON.stringify({uid, displayName, email, photoURL} as User));
      localStorage.setItem("access_token", signed);

      setUser({uid, displayName, email, photoURL});
      setLoadingAuthentication(false);
      navigate("/panel");
    }).catch((error: any) => {
      setLoadingAuthentication(false);
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('Error Google SignIn:', errorCode, errorMessage);
    });
  };
  */

  return <>
    <Alert alert={alert} />

    <div className="Signup container mt-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-4">

          <div className="text-center text-primary">
            <img src="https://criarti.com/c@2x.png" alt="Criarti" className="img-fluid" style={{ width: "60px" }} />
          </div>

          <Separator size={40} />

          <div className="card bg-white shadow-sm rounded-3 border-0">
            <div className="card-body">

              <div className="text-center">
                <p>
                  <strong>Crie sua conta</strong> ou <Link to="/login" data-testid="signup-back" className="link-primary inline">Faça Login</Link>
                </p>

                <Separator size={20} />

                <div className="row justify-content-center">
                  <div className="col-12 col-md-10">

                    <form onSubmit={(e: any) => {
                      e.preventDefault();
                      formikSignup.handleSubmit();
                    }}>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          name="displayName"
                          data-testid="signup-name"
                          autoComplete="off"
                          onChange={formikSignup.handleChange}
                          value={formikSignup.values.displayName}
                          disabled={loadingSignup}
                          className="form-control"
                          placeholder="Seu nome completo" />
                      </div>

                      <div className="form-group mb-3">
                        <input
                          type="email"
                          name="email"
                          data-testid="signup-email"
                          autoComplete="off"
                          onChange={formikSignup.handleChange}
                          value={formikSignup.values.email}
                          disabled={loadingSignup}
                          className="form-control"
                          placeholder="E-mail" />
                      </div>

                      <div className="form-group mb-3">
                        <input
                          type="password"
                          name="password"
                          data-testid="signup-password"
                          autoComplete="new-password"
                          onChange={formikSignup.handleChange}
                          value={formikSignup.values.password}
                          disabled={loadingSignup}
                          className="form-control"
                          placeholder="Senha" />
                      </div>

                      <PasswordStrengthBar
                        password={formikSignup.values.password}
                        onChangeScore={setPasswordScore}
                        shortScoreWord="muito fraca"
                        scoreWords={["muito fraca", "fraca", "normal", "boa", "forte"]} />

                      <Separator size={10} />

                      <div className="text-start">
                        <small className="text-muted">
                          Sua senha deve ter no mínimo 6 caracteres, maiúsculas, minúsculas, números e caracteres especiais.
                        </small>
                      </div>

                      <Separator size={20} />

                      <div className="form-group mb-3">
                        <input
                          type="password"
                          autoComplete="off"
                          name="confirmPassword"
                          data-testid="signup-password-confirm"
                          onChange={formikSignup.handleChange}
                          value={formikSignup.values.confirmPassword}
                          disabled={loadingSignup}
                          className="form-control"
                          placeholder="Confirme a Senha" />
                      </div>

                      <div className="d-grid">
                        <button
                          type="submit"
                          data-testid="signup-submit"
                          disabled={loadingSignup || !formikSignup.isValid || passwordScore < 3}
                          className="btn btn-primary btn-block">
                            <Loading loading={loadingSignup} parent="inline" color="text-white" />
                            {!loadingSignup && <i className="fas fa-check-circle me-2"></i>} Criar Conta
                        </button>
                      </div>
                    </form>

                  </div>
                </div>

                <Separator size={40} />

                {/*
                <small>
                  <span className="text-muted">
                      Ao se cadastrar, você declara que leu e concorda com os <Link to="/terms" data-testid="signup-terms" className="link-primary inline" target="_blank">Termos de Uso</Link> desta plataforma.
                  </span>
                </small>
                */}
              </div>

            </div>
          </div>{/* /.card */}

        </div>
      </div>
    </div>

    <Footer />
  </>
};

export default Signup;