import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AlertProvider, ConfirmProvider } from '@moreirapontocom/npmhelpers';
import { DataProvider } from './context/Data.context';
import RequireAuth from './services/requireAuth.component';
import axios from 'axios';

import ErrorHandlerProvider from './views/ErrorHandlerProvider/ErrorHandlerProvider';
import SubscribeView from './views/Subscribe/Subscribe.view';
import Login from './views/Login/Login';
import CancelSubscriptionView from './views/CancelSubscription/CancelSubscription.view';
import ArticlesView from './views/Articles/Articles.view';
import ArticleEditorView from './views/ArticleEditor/ArticleEditor.view';
import SubscribersView from './views/Subscribers/Subscribers.view';
import PanelView from './views/Panel/Panel.view';
import ResultsView from './views/Results/Results.view';
import Signup from './views/Signup/Signup';
import NewAccountSuccess from './views/NewAccountSuccess/NewAccountSuccess';
import RecoverPassword from './views/RecoverPassword/RecoverPassword';
import ProfileView from './views/Profile/Profile.view';
import AssetsView from './views/Assets/Assets.view';

import './index.scss';

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL || "";

axios.interceptors.request.use(function (config) {
  const token: any = localStorage.getItem('access_token');
  if (!token) return config;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const routes: any = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/recoverPassword',
    element: <RecoverPassword />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/welcome',
    element: <NewAccountSuccess />,
  },
  // {
  //   path: '/:authorHandler',
  //   element: <SubscribeView />,
  // },
  {
    path: '/leave/:authorHandler',
    element: <CancelSubscriptionView />,
  },
  // @deprecated
  // {
  //   path: '/:authorHandler/:slug',
  //   element: <Article />,
  // },
  {
    path: '/panel',
    element: <RequireAuth component={<PanelView />} />,
    errorElement: <ErrorHandlerProvider />,
    children: [
      {
        path: '/panel/subscribers',
        element: <RequireAuth component={<SubscribersView />} />,
      },
      {
        path: '/panel/profile',
        element: <RequireAuth component={<ProfileView />} />,
      },
      {
        path: '/panel/assets',
        element: <RequireAuth component={<AssetsView />} />,
      },
      {
        path: '/panel/articles',
        element: <RequireAuth component={<ArticlesView />} />,
      },
      {
        path: '/panel/articles/:articleId',
        element: <RequireAuth component={<ArticleEditorView />} />,
      },
      {
        path: '/panel/articles/:articleId/results',
        element: <RequireAuth component={<ResultsView />} />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorHandlerProvider />,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AlertProvider>
      <DataProvider>
        <ConfirmProvider>
          <RouterProvider router={routes} />
        </ConfirmProvider>
      </DataProvider>
    </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
